import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { VetspirePatient } from '../../interfaces/VetspirePatient'
import { emojiFor, speciesFor } from '../../lib/helpers'

interface Props {
  confirm: (confirmed: boolean) => void
  notes: string
  patient: VetspirePatient
}

export default function PimsPatientSearchConfirm(props: Props) {
  const { patient, notes, confirm } = props

  return (
    <Modal className="max-width-600px" size="lg" centered fade isOpen onClick={() => confirm(false)}>
      <ModalHeader onClick={() => confirm(false)}>Confirm import patient from Vetspire</ModalHeader>

      <ModalBody className="">
        <p className="text-l">
          You are about to import <span className="bold">{patient.name}</span>'s data and history from Vetspire into Radimal. Please
          confirm this is the correct patient:
        </p>
        <ul className="mt-3 text-m">
          <li className="mb-2">
            <span className="bold mr-1">Patient name:</span>
            <span className="mr-1 text-l"> {emojiFor(speciesFor(patient.species))}</span> {patient.name}
          </li>
          <li className="mb-2">
            <span className="bold">Patient breed:</span> {patient.breed}
          </li>
          {patient.client.name && (
            <li className="mb-2">
              <span className="bold">Client name:</span> {patient.client.name}
            </li>
          )}
          {patient.client.email && (
            <li className="mb-2">
              <span className="bold">Client email:</span> {patient.client.email}
            </li>
          )}
          <li className="mb-2">
            <span className="bold">Notes:</span>{' '}
            <i>
              "{notes.slice(0, 200)}
              {notes.length > 200 && '...'}"
            </i>
          </li>
        </ul>
      </ModalBody>

      <ModalFooter>
        <Button className="width-100px" outline color="muted" onClick={() => confirm(false)}>
          Cancel
        </Button>

        <Button className="ml-2" color="success" onClick={() => confirm(true)}>
          Yes, this is the correct patient
        </Button>
      </ModalFooter>
    </Modal>
  )
}
