import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import moment from 'moment'
import { Alert, Button, Spinner } from 'reactstrap'
import { Formik, Form } from 'formik'
import { capitalize, isInteger, pick, range, intersection, isBoolean, partition } from 'lodash'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'

import AiIndications, { IndicationsView } from '../components/ai-assessment/Indications'
import Attachments from '../components/request-consult/attachments'
import ErrorMessage from '../components/common/ErrorMessage'
import Images from '../components/request-consult/images'
import InternalMedicineChecklist from '../components/request-consult/internalMedicineChecklist'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import PastCases from '../components/request-consult/pastCases'
import PimsPatientSearch from '../components/consultations/PimsPatientSearch'
import PriceCalculator from '../utils/PriceCalculator'
import SelectReferringVet from '../components/request-consult/selectReferringVet'
import StatBadge from '../components/stat/StatBadge'
import StatSelect from '../components/stat/StatSelect'
import defaultReactSelectStyles from '../utils/defaultReactSelectStyles'
import { Case_cases, Case_cases_consultations } from '../hasura/graphQlQueries/types/Case'
import { CasesState, fetchCaseAction, casesSelector, unsetCaseAction } from '../hasura/slices/cases'
import { FormikInput, Input } from '../components/common/Input'
import { FormikRadioButton } from '../components/common/FormikRadioButton'
import { NotificationId, setNotificationAction } from '../hasura/slices/notifications'
import { OrganizationEvent } from '../lib/organizationEventTypes'
import { PatientParams, findOption, formatOtherCaseNotes, getPatientParams } from '../components/request-consult/helpers'
import { QueryName } from '../hasura/queryNames'
import { User_users, User_users_organization_distribution_emails } from '../hasura/graphQlQueries/types/User'
import { additionalImagesCount } from '../lib/combineCompareHelpers'
import { insertOrganizationEventAction, insertOrganizationEventsAction } from '../hasura/slices/organizations'
import { insertPimsPatientAction, updatePatientAction } from '../hasura/slices/patients'
import { slackMessageAction, usersSelector, UsersState } from '../hasura/slices/users'

import {
  ConsultationType,
  Modality,
  availableConsultationTypeAfterFirstRequest,
  canRequestInternalMedicineConsultation,
  getConsultationTypeData,
  getConsultationTypeForId,
  getConsultationTypeForModality,
  getConsultationTypeOptions,
  modalityFor,
} from '../lib/modalityHelpers'

import {
  consultationsSelector,
  ConsultationsState,
  requestConsultationAction,
  updateConsultationSendingVetNotesAction,
} from '../hasura/slices/consultations'

import {
  CatscanRegions,
  GENDER_OPTIONS,
  INTACT,
  NEUTERED,
  NEUTERED_OPTIONS,
  OtherCase,
  requestConsultValidationSchema,
} from '../components/request-consult/config'

import {
  ALL_SPECIES,
  Option,
  Species,
  emojiFor,
  inCase,
  isExotic,
  isOnAiFullConsultPlan,
  isSuperAdmin,
  keyForAwsS3Url,
  pluralize,
  searchQueryParams,
  speciesFor,
  trackHotjarEvent,
} from '../lib/helpers'

// @ts-ignore
import dangerIcon from '../lib/images/danger-blue.svg'

// @ts-ignore
import { usePrevious } from '../hooks/usePrevious'

enum RequestConsultationStatus {
  New,
  Edit,
  Complete,
}

export default function RequestConsult() {
  const dispatch = useDispatch()

  const { radimalCase }: CasesState = useSelector(casesSelector)
  const isQueryingCase = useSelector(casesSelector).isQuerying
  const { accessToken, user, vetStatus, role, defaultTurnaroundTime, isRadimalEnterprise }: UsersState = useSelector(usersSelector)
  const { isQuerying, presignedCaseImageUrls }: ConsultationsState = useSelector(consultationsSelector)

  const [consultation, setConsultation] = useState<Case_cases_consultations | undefined>()
  const [consultationAttachmentAwsS3Urls, setConsultationAttachmentAwsS3Urls] = useState<string[]>([])
  const [consultationType, setConsultationType] = useState<ConsultationType>(ConsultationType.Xray)
  const [changeConsultationTypeEnabled, setChangeConsultationTypeEnabled] = useState(false)
  const [displayCancelRequestAlert, setDisplayCancelRequestAlert] = useState(false)
  const [displayDicomStandardHelpLink, setDisplayDicomStandardHelpLink] = useState(false)
  const [displayIncludeOtherCasesAlert, setDisplayIncludeOtherCasesAlert] = useState({ show: false, field: 'notes' })
  const [distributionEmails, setDistributionEmails] = React.useState<User_users_organization_distribution_emails[]>([])
  const [isCreatingAttachments, setIsCreatingAttachments] = useState(false)
  const [notifyStat, setNotifyStat] = useState(false)
  const [otherCases, setOtherCases] = useState<OtherCase[]>([])
  const [region, setRegion] = useState<CatscanRegions | undefined>()
  const [species, setSpecies] = useState(Species.Dog)
  const [statType, setStatType] = useState<any | undefined>(undefined)
  const [status, setStatus] = useState(RequestConsultationStatus.New)
  const [unselectedImageUrls, setUnselectedImageUrls] = useState<string[]>([])
  const [vetspirePatientId, setVetspirePatientId] = useState<string | undefined>()

  const isEditing = status === RequestConsultationStatus.Edit

  const pastCases =
    radimalCase?.patient.cases.filter((c) => {
      const isSameCase = c.id === radimalCase?.id
      const isSameModality = modalityFor(c) === modalityFor(radimalCase)
      return !isSameCase && isSameModality
    }) || []

  const id = parseInt(searchQueryParams('i') || '', 10)

  const unselectedImages = (radimalCase?.medical_images || [])
    .filter((m) => {
      const key = m.aws_s3_url && keyForAwsS3Url(m.aws_s3_url)
      return key && unselectedImageUrls.some((d) => d.includes(key))
    })
    .map((m) => pick(m, ['id', 'dicom_server_instance_id', 'is_video']))

  const previousUser: User_users | undefined = usePrevious(user)
  const previousCase: Case_cases | undefined = usePrevious(radimalCase)

  const fetchCaseInterval = useRef<any | null>(null)

  const fetchCase = () => dispatch(fetchCaseAction(accessToken, id, true))

  /*
    Effects
  */

  useEffect(() => {
    if (consultationType === ConsultationType.InternalMedicine) {
      trackHotjarEvent('started_internal_medicine_request')
    }
  }, [consultationType])

  useEffect(() => {
    // handles situations where STAT is turned off after a STAT turnaround time was selected
    if (vetStatus && !vetStatus.stat_available && statType) {
      setStatType(undefined)
    }
  }, [vetStatus])

  useEffect(() => {
    if (!radimalCase) return

    if (!radimalCase.consultations.length) {
      setConsultationType(
        (consultation?.consultation_type_id
          ? getConsultationTypeForId(consultation.consultation_type_id)?.consultationType
          : getConsultationTypeForModality(modality)?.consultationType) || ConsultationType.Xray
      )
    } else {
      const nextConsultationType = availableConsultationTypeAfterFirstRequest(radimalCase)

      if (radimalCase.consultations[0] && searchQueryParams('e') === 'true') {
        setStatus(RequestConsultationStatus.Edit)
        setConsultation(radimalCase.consultations[0])
        setConsultationType(getConsultationTypeForId(radimalCase.consultations[0].consultation_type_id)!.consultationType)
      } else if (nextConsultationType) {
        setConsultationType(nextConsultationType)
      }
    }
  }, [radimalCase])

  useEffect(() => {
    if (!user || previousUser || !accessToken || !isInteger(id)) return

    clearInterval(fetchCaseInterval.current)
    fetchCaseInterval.current = setInterval(fetchCase, 15 * 1000)
    fetchCase()

    if (canRequestInternalMedicineConsultation(user)) {
      setChangeConsultationTypeEnabled(true)
    }

    return () => {
      dispatch(unsetCaseAction())
      clearInterval(fetchCaseInterval.current)
    }
  }, [user, accessToken, previousUser])

  useEffect(() => {
    // @ts-ignore
    if (!previousUser || previousUser.organization.distribution_emails.length === user?.organization.distribution_emails.length) {
      return
    }

    setDistributionEmails(distributionEmails)
  }, [previousUser, user])

  useEffect(() => {
    if (!user) return

    setDistributionEmails(user.organization.distribution_emails.filter((d) => d.send_human_reports))
  }, [user])

  useEffect(() => {
    if (!radimalCase || radimalCase.id !== id || previousCase) return

    setSpecies(speciesFor(radimalCase.patient.species))

    if (pastCases.length) {
      const defaultOtherCases: OtherCase[] = pastCases
        .filter((c) => Math.abs(moment(radimalCase.created_at).diff(moment(c.created_at), 'hours')) < 3)
        .map((c) => ['combine', c.id])
      if (defaultOtherCases.length >= 2) setDisplayDicomStandardHelpLink(true)
      setOtherCases(defaultOtherCases)
    }
  }, [radimalCase])

  // if (radimalCase?.consultation?.receiving_vet_notes) {
  //   return (
  //     <Layout>
  //       <ErrorMessage
  //         message={`Consultation for ${radimalCase.patient.display_name} is ${isComplete ? 'complete' : 'in progress'}.`}
  //       />
  //     </Layout>
  //   )
  // }

  if (!radimalCase) {
    return <Layout>{isQueryingCase[QueryName.FetchCase] ? null : <ErrorMessage message="Case not found." />}</Layout>
  }

  const otherCaseNotes = formatOtherCaseNotes(otherCases, pastCases)

  /*
    Methods
  */

  const handleEdit = (patient: PatientParams, additionalNotes?: string) => {
    if (!consultation) return

    if (additionalNotes) {
      const [sendingVetNotes, compareNotes] = consultation?.sending_vet_notes?.split('\\n\\n* ') || []
      let updatedNotes = `${sendingVetNotes}\n\n[Additional Notes] ${additionalNotes}`
      if (compareNotes) updatedNotes += `\\n\\n* ${compareNotes}`
      dispatch(updateConsultationSendingVetNotesAction(accessToken, consultation.id, updatedNotes))
    }
    dispatch(updatePatientAction(accessToken, patient))
    const event = { organization_id: user?.organization.id, organization_event_type_id: OrganizationEvent.EditedConsult }
    dispatch(insertOrganizationEventAction(accessToken, event))
    dispatch(setNotificationAction(NotificationId.ConsultationEdited))
    navigate('/cases')
  }

  const handleFormSubmit = async (values: any) => {
    const patient = getPatientParams(radimalCase!.patient.id, values)

    if (isEditing) {
      handleEdit(patient, values.additional_notes)
      return
    }

    const imagesCount =
      (parseInt(values.imagesCount, 10) || radimalCase.medical_images.length) +
      additionalImagesCount(otherCaseNotes) -
      unselectedImageUrls.length
    let sending_vet_notes = `${imagesCount} image(s) - ${values.notes}`

    if (otherCaseNotes?.length) sending_vet_notes += otherCaseNotes

    const overflow = Boolean(radimalCase.dicom_server?.organization?.enterprise.default_overflow)
    const consultation = {
      case_id: radimalCase!.id,
      sending_vet_notes: sending_vet_notes.replace(/\n{2,}/g, '\n\n'),
      sending_vet_private_notes: values.private_notes,
      pims_reference_number: values.pims_reference_number,
      stat_type_id: statType?.value,
      price_amount: totalPriceWithoutExtraImageCost,
      distribution_email_ids_denormalized: distributionEmails.map((d) => d.id).join(','),
      consultation_type_id: getConsultationTypeData(consultationType)?.consultationTypeId,
      consultation_attachments: { data: consultationAttachmentAwsS3Urls.map((url) => ({ aws_s3_url: url })) },
      overflow,
      overflowed_at: overflow ? 'now()' : null,
    }

    const params: any = { consultation, patient, unselectedImages }
    if (notifyStat) params['notifyStatUserId'] = user?.id
    await dispatch(requestConsultationAction(params))
    if (vetspirePatientId) {
      dispatch(insertPimsPatientAction(accessToken, { patient_id: radimalCase.patient.id, vetspire_patient_id: vetspirePatientId }))
    }
    trackEvents(values.private_notes, statType?.value, unselectedImageUrls, notifyStat)
    dispatch(setNotificationAction(NotificationId.ConsultationRequested))
    navigate('/cases')
  }

  const trackEvents = (privateNotes: any, statTypeId: any, unselectedImageUrls: string[], notifyStat: boolean) => {
    if (!user) return

    const events = [
      OrganizationEvent.RequestedConsult,
      unselectedImageUrls.length ? OrganizationEvent.ReducedImages : null,
      statTypeId ? OrganizationEvent.SelectedStatTimings : null,
      privateNotes ? OrganizationEvent.AddedPrivateNotes : null,
      otherCases.some((o) => o[0] === 'compare') ? OrganizationEvent.ComparedStudies : null,
      otherCases.some((o) => o[0] === 'combine') ? OrganizationEvent.CombinedStudies : null,
      notifyStat ? OrganizationEvent.NotifyStat : null,
    ]
      .filter((e) => e)
      .map((organization_event_type_id) => ({ organization_event_type_id, organization_id: user.organization.id }))
    dispatch(insertOrganizationEventsAction(accessToken, events))
    trackHotjarEvent('completed_request_consult')
  }

  const handleOnChangeNotes = (e: any) => {
    if (otherCases.length || !pastCases.length) return

    if (
      intersection(
        e.target.value.split(' ').map((word: string) => word.toLowerCase()),
        ['combine', 'compare', 'previous']
      ).length
    ) {
      setDisplayIncludeOtherCasesAlert({ show: true, field: e.target.name })
    }
  }

  const handleOnChangeAdditionalNotes = (e: any) => {
    if (
      intersection(
        e.target.value.split(' ').map((word: string) => word.toLowerCase()),
        ['cancel', 'cancelled', 'canceled']
      ).length
    ) {
      setDisplayCancelRequestAlert(true)
    } else {
      setDisplayCancelRequestAlert(false)
    }
  }

  const presignedUrls = inCase(presignedCaseImageUrls, radimalCase)
  const { age, birthdate } = radimalCase.patient
  const validBirthdate = moment(birthdate).isBefore(moment().subtract(1, 'day'))
  const validAge = range(0, 10).some((n) => (age || '').includes(String(n)))
  const speciesOptions: Option[] = ALL_SPECIES.map((species) => ({ value: species, label: capitalize(species) }))
  const modality = modalityFor(radimalCase)
  const maxImages = modality === Modality.Xray ? 50 : 9999
  const calculator = new PriceCalculator(radimalCase)
  const [removedVideosCount, removedImagesCount] = partition(unselectedImages, 'is_video').map((a) => a.length)
  const { extraImageCost, totalPriceWithoutStat, totalPriceWithoutExtraImageCost } = calculator.costs(
    otherCaseNotes,
    removedImagesCount,
    removedVideosCount,
    species,
    consultationType,
    statType?.value,
    region
  )

  const displayAiIndications =
    consultationType === ConsultationType.Xray &&
    !isEditing &&
    (isOnAiFullConsultPlan(user) || (!isRadimalEnterprise && user?.organization.enterprise.ai_enabled) || isSuperAdmin(role))

  const includeOtherCasesAlert = (
    <Alert color="secondary" className="text-s width-fit-content pl-2 pr-3 py-2 flex-center mt-2">
      Do you want to include other cases? Please see "Images" above.
    </Alert>
  )

  const requestedConsultationTypes = radimalCase.consultations.map((c) => getConsultationTypeForId(c.consultation_type_id))

  const consultationTypeOptions = getConsultationTypeOptions(modality).filter(
    (o) => !requestedConsultationTypes.some((c) => c?.consultationType === o.value)
  )

  const isInternalMedicineRequest = consultationType === ConsultationType.InternalMedicine

  const inProgressConsults = isEditing ? [] : radimalCase.consultations.filter((c) => c.status === 'requested')
  if (!user) return null

  return (
    <Layout>
      <MainBox className="d-flex justify-content-start" defaultPadding>
        <div>
          {inProgressConsults.length > 0 && (
            <Alert className="text-s width-fit-content flex-center" color="secondary">
              <img className="icon-xxxs mr-1" src={dangerIcon} />
              This case already has a {radimalCase.consultations[0].consultation_type.display_name.toLowerCase()} consultation in
              progress.{' '}
              <a
                className="pointer underline px-1"
                href={`/request-consult?i=${radimalCase.id}&e=true&c=${radimalCase.consultations[0].id}`}
              >
                Click here
              </a>{' '}
              to edit this request.
            </Alert>
          )}

          <div className="d-flex justify-content-start align-items-center">
            <h4 className="mb-0">
              <span className="bold">{isEditing ? 'Edit' : 'Request'} full consult</span>
            </h4>

            {!isEditing && (
              <Select
                classNamePrefix="react-select"
                className="width-200px ml-2"
                isSearchable={false}
                isDisabled={consultationTypeOptions.length === 1 || isEditing || !changeConsultationTypeEnabled}
                onChange={(option: any) => setConsultationType(option.value)}
                options={consultationTypeOptions}
                placeholder="Consultation type..."
                value={consultationTypeOptions.find((o) => o.value === consultationType)}
              />
            )}
          </div>

          <div className="text-s mt-2 mb-3">
            <p className="m-0">
              All images read by a Board Certified Radiologist (DACVR/ECVDI). Reports completed within{' '}
              {isExotic(species) ? 72 : defaultTurnaroundTime} hours.
            </p>
          </div>

          <Formik
            initialValues={{
              age: validBirthdate || !age || !validAge ? '' : age,
              birthdate: validBirthdate ? moment(birthdate).format('MM/DD/YYYY') : '',
              breed: radimalCase.patient.breed || '',
              display_name: radimalCase.patient.display_name || '',
              gender: findOption(GENDER_OPTIONS, radimalCase.patient.gender),
              imagesCount: radimalCase.medical_images.length || 0,
              neutered: isBoolean(radimalCase.patient.neutered) ? (radimalCase.patient.neutered ? NEUTERED : INTACT) : undefined,
              notes: consultation?.sending_vet_notes?.replace(/(\\n)/g, '\n') || '',
              private_notes: consultation?.sending_vet_private_notes?.replace(/(\\n)/g, '\n') || '',
              regions: '',
              species: ALL_SPECIES.find((s) => s === radimalCase.patient.species) || '',
              weight: radimalCase.patient.weight || '',
            }}
            enableReinitialize
            onSubmit={handleFormSubmit}
            validateOnMount
            validationSchema={requestConsultValidationSchema(maxImages, modality)}
          >
            {(formik) => {
              useEffect(() => {
                const region = formik?.values.regions as CatscanRegions
                setRegion(region)
              }, [formik?.values.regions])

              return (
                <Form className="my-2">
                  <p className="subheader">patient info</p>

                  <PimsPatientSearch
                    user={user}
                    update={(patient: any, notes?: string) => {
                      setVetspirePatientId(patient.id)
                      formik.setValues({ ...formik.values, ...patient, ...{ notes } })
                    }}
                  />

                  <div>
                    <div className="mb-3 d-flex gap-20px">
                      <div>
                        <p className="text-s m-0 bold">Species</p>

                        <Select
                          className="width-200px"
                          onChange={(option: any) => {
                            setSpecies(speciesFor(option.value))
                            formik.setFieldValue('species', option.value)
                          }}
                          options={speciesOptions}
                          placeholder="Species..."
                          value={speciesOptions.find((s) => s.value === formik.values?.species)}
                          isOptionDisabled={(o: Option) => isExotic(o.value) && !vetStatus?.available_for_exotics}
                          // @ts-ignore
                          styles={defaultReactSelectStyles}
                          formatOptionLabel={(option: any) => (
                            <p className="d-flex align-items-center text-m m-0 p-1">
                              <span className="mr-2 text-l">{emojiFor(option.value)}</span>
                              {option.label}
                            </p>
                          )}
                        />

                        {!formik.values?.species && <p className="mt-1 error">Species required.</p>}
                      </div>

                      <FormikInput containerClassName="mb-2" label="Name" name="display_name" placeholder="Patient name" type="text" />
                    </div>

                    <div className="d-flex gap-20px">
                      <div className="d-flex flex-column gap-10px">
                        <FormikInput
                          label={isExotic(formik.values.species) ? 'Sub-species' : 'Breed'}
                          name="breed"
                          placeholder={isExotic(formik.values.species) ? 'Patient sub-species' : 'Patient breed'}
                          type="text"
                          className="width-200px"
                        />
                        <FormikInput label="Weight" name="weight" placeholder="ex. 15 lbs" type="text" className="width-200px" />
                      </div>

                      <div className="d-flex flex-column gap-10px width-200px">
                        <FormikRadioButton label="Gender" name="gender" options={GENDER_OPTIONS} />
                        <FormikRadioButton label="Neutered?" name="neutered" options={NEUTERED_OPTIONS} />
                      </div>

                      <div className="d-flex flex-column gap-10px">
                        <div>
                          <p className="text-s m-0 bold">Age</p>
                          <Input
                            autoComplete="off"
                            className="data-hj-allow width-200px"
                            placeholder="Estimated age"
                            value={formik.values.age}
                            onChange={(e) => formik.setFieldValue('age', e.target.value)}
                          />
                        </div>

                        <div>
                          <p className="text-s m-0 bold">Birthdate</p>
                          <Input
                            autoComplete="off"
                            className="data-hj-allow width-200px"
                            placeholder="Birthdate (mm/dd/yyyy)"
                            value={formik.values.birthdate}
                            onChange={(e) => formik.setFieldValue('birthdate', e.target.value)}
                          />
                          <p className="text-xxs text--gray7 m-0">Month, Day, Year</p>
                          {formik.errors.age && <p className="error">{formik.errors.age}</p>}
                        </div>
                      </div>
                    </div>
                  </div>

                  {!isEditing && (
                    <div>
                      <p className="subheader">images</p>

                      <Images
                        extraImageCost={extraImageCost}
                        formik={formik}
                        modality={modality}
                        pastCases={pastCases}
                        presignedUrls={presignedUrls}
                        radimalCase={radimalCase}
                        region={region}
                        setUnselectedImageUrls={setUnselectedImageUrls}
                        unselectedImageUrls={unselectedImageUrls}
                      />

                      {pastCases.length > 0 && (
                        <PastCases
                          caseCreatedAt={radimalCase.created_at}
                          data={pastCases}
                          displayDicomStandardHelpLink={displayDicomStandardHelpLink}
                          otherCases={otherCases}
                          patient={radimalCase.patient}
                          setDisplayIncludeOtherCasesAlert={setDisplayIncludeOtherCasesAlert}
                          setOtherCases={setOtherCases}
                        />
                      )}
                    </div>
                  )}

                  {displayAiIndications && <AiIndications view={IndicationsView.RequestForm} />}

                  <div>
                    <p className="subheader">case notes</p>

                    <FormikInput
                      as="textarea"
                      containerClassName="mb-2"
                      disabled={isEditing}
                      label="Report Notes"
                      name="notes"
                      placeholder="Notes"
                      style={{ width: '100%', maxWidth: '700px', minHeight: '200px' }}
                      type="text"
                      onChange={(e: any) => {
                        formik.setFieldValue('notes', e.target.value)
                        handleOnChangeNotes(e)
                      }}
                    />

                    {isInternalMedicineRequest && <InternalMedicineChecklist />}

                    {displayIncludeOtherCasesAlert.show && displayIncludeOtherCasesAlert.field === 'notes' && includeOtherCasesAlert}

                    {!(isEditing && !consultation?.sending_vet_private_notes) && (
                      <FormikInput
                        as="textarea"
                        disabled={isEditing}
                        label="Private Notes"
                        name="private_notes"
                        placeholder="Private notes"
                        style={{ width: '100%', maxWidth: '700px', minHeight: '50px' }}
                        subLabel="Only seen by radiologists. These notes will not appear on the final report."
                        type="text"
                        onChange={(e: any) => {
                          formik.setFieldValue('private_notes', e.target.value)
                          handleOnChangeNotes(e)
                        }}
                      />
                    )}

                    {displayIncludeOtherCasesAlert.show &&
                      displayIncludeOtherCasesAlert.field === 'private_notes' &&
                      includeOtherCasesAlert}

                    {isEditing && (
                      <FormikInput
                        as="textarea"
                        label="Additional Report Notes"
                        name="additional_notes"
                        placeholder="Additional notes"
                        subLabel="These notes will appear on the final report."
                        style={{ width: '100%', maxWidth: '700px', minHeight: '50px' }}
                        type="text"
                        onChange={(e: any) => {
                          formik.setFieldValue('additional_notes', e.target.value)
                          handleOnChangeAdditionalNotes(e)
                        }}
                      />
                    )}
                    {displayCancelRequestAlert && (
                      <Alert color="secondary" className="text-s width-fit-content pl-2 pr-3 py-2 flex-center mt-2">
                        This form can be used to provide additional patient history and signalment to your specialist.
                      </Alert>
                    )}
                  </div>

                  {!isEditing && isInternalMedicineRequest && (
                    <div>
                      <p className="subheader">Attachments</p>

                      <Attachments
                        caseId={radimalCase.id}
                        consultationAttachmentAwsS3Urls={consultationAttachmentAwsS3Urls}
                        isCreatingAttachments={isCreatingAttachments}
                        setConsultationAttachmentAwsS3Urls={setConsultationAttachmentAwsS3Urls}
                        setIsCreatingAttachments={setIsCreatingAttachments}
                      />
                    </div>
                  )}

                  {!isEditing && (
                    <div>
                      <p className="subheader">finalize</p>

                      <SelectReferringVet distributionEmails={distributionEmails} setDistributionEmails={setDistributionEmails} />

                      {user?.organization.pims_email && (
                        <FormikInput
                          label="PIMS reference number"
                          name="pims_reference_number"
                          placeholder="Reference #"
                          type="text"
                        />
                      )}

                      <div className="mb-2 mt-4">
                        <p className="text-s bold m-0">Turnaround Time</p>

                        <StatSelect
                          _case={radimalCase}
                          consultationType={consultationType}
                          species={species}
                          statType={statType}
                          totalPriceBeforeStat={totalPriceWithoutStat}
                          setStatType={(statType: any) => {
                            setStatType(statType)
                          }}
                        />

                        {vetStatus && !vetStatus.stat_available && (
                          <label className={`text-s m-0`}>
                            <input
                              checked={notifyStat}
                              className="mr-1"
                              onClick={() => {
                                if (!notifyStat) {
                                  const msg = `${user?.organization.display_name} is interested in STAT if someone is available.`
                                  dispatch(slackMessageAction('stat', msg))
                                }
                                setNotifyStat(!notifyStat)
                              }}
                              readOnly
                              role="button"
                              type="radio"
                            />
                            {notifyStat
                              ? 'We will send you a message/email when STAT becomes available and you can upgrade your case.'
                              : 'Notify me when STAT is available.'}
                          </label>
                        )}

                        {statType?.value && modality === Modality.Xray && (
                          <div className="mt-3">
                            <FormikInput
                              subLabel={
                                radimalCase.medical_images.length
                                  ? `${pluralize(
                                      'image',
                                      radimalCase.medical_images.length
                                    )} received, last received ${radimalCase.medical_images
                                      .map((a) => moment(a.created_at))
                                      .sort((a, b) => b.diff(a))[0]
                                      .fromNow()}`
                                  : undefined
                              }
                              label="Total # of Images (incl. not yet uploaded)"
                              name="imagesCount"
                              placeholder="ex. 3"
                              type="text"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="my-4">
                    <div className="d-flex align-items-end">
                      <Button
                        className="width-150px"
                        size="lg"
                        color="primary"
                        disabled={
                          isCreatingAttachments ||
                          isQuerying[QueryName.RequestConsultation] ||
                          !formik.isValid ||
                          displayCancelRequestAlert
                        }
                      >
                        Submit
                      </Button>

                      {isQuerying[QueryName.RequestConsultation] && <Spinner size="sm" className="ml-3" color="primary" />}
                    </div>

                    {statType?.value && (
                      <Alert color="secondary" className="text-s width-fit-content pl-2 pr-3 py-2 flex-center mt-2">
                        <img className="icon-xxxs mr-1" src={dangerIcon} />
                        Please double check all info before submitting STAT.
                      </Alert>
                    )}
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>

        <div className="hide-mobile pl-2" style={{ position: 'sticky', top: 15, height: 'fit-content' }}>
          <StatBadge />
        </div>
      </MainBox>
    </Layout>
  )
}
